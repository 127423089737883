import {
    AuthenticationState,
    EventName,
    LogoutArgs,
    MagicLinkStateArgs,
    StateArgs,
    Subscription,
    User,
    UserInput,
} from '../types';
import {
    MxAnalyticsDispatcher,
    getDispatcher,
} from '@soluto-private/mx-analytics';
import {
    getAuthorizationRequestsCount,
    performAuthorizationRequest,
    processAuthorizationResponse,
    resetAuthorizationRequestsCount,
} from './oidc/authorizationRequest';
import { requestToken, revokeToken } from './oidc/requestToken';

import type { AccessToken } from './types';
import { AuthorizationError } from './oidc/errors';
import EventEmitter from './EventEmitter';
import { UserBrowser } from '@soluto-private/mx-user-browser';
import { assertTokenExpiration } from './utils/assertTokenExpiration';
import { createUserFromToken } from './utils/createUserFromToken';
import decodeJWT from 'jwt-decode';
import extractSubscriptions from './utils/extractSubscriptions';
import { getQueryParam } from '../utils/queryParams';
import { monitor } from '@soluto-private/mx-monitor';
import { performSignoutRequest } from './oidc/performSignoutRequest';
import sleep from '../utils/sleep';
import { verifyMagicLink } from '../utils/verifyMagicLink';

const USER_STORAGE_KEY = 'ONESERVICE_AUTH_USER';

const authStateToAnalyticsEvent = {
    [AuthenticationState.LoggedIn]: 'OneService_UserLoggedIn',
    [AuthenticationState.LoggedOut]: 'OneService_UserLoggedOut',
    [AuthenticationState.InProgress]: 'OneService_LoginInProgress',
};

export class Persona {
    private _eventTarget: EventTarget;
    private _currentUser: User | null | undefined;
    private _authState: AuthenticationState;
    private _subscriptions: Subscription[] | undefined;
    private _loginRedirectUri: string;
    private _magicLinkRedirectUri: string;
    private _analyticsDispatcher: MxAnalyticsDispatcher<void>;

    constructor() {
        this._loginRedirectUri = `${window.location.protocol}//${window.location.host}/auth/login`;
        this._magicLinkRedirectUri = `${window.location.protocol}//${window.location.host}/auth/magic-link`;
        this._eventTarget = new EventEmitter();
        this._currentUser = null;
        this._authState = AuthenticationState.LoggedOut;
        this._analyticsDispatcher = getDispatcher({
            path: '/auth',
            packageName: '@soluto-private/mx-app-authentication',
            friendlyName: 'mx-auth',
            ignoreScope: false,
            skipBuffer: true,
        });
        window.addEventListener('click', () => void this.eventHandler());
        window.addEventListener('load', () => void this.eventHandler());
        this.hydrate();
    }

    eventHandler = async () => {
        if (
            this._currentUser &&
            this._authState === AuthenticationState.LoggedIn
        ) {
            const parsedAccessToken = decodeJWT<AccessToken>(
                this._currentUser.access_token
            );
            if (!assertTokenExpiration(parsedAccessToken)) {
                await this.logout({
                    redirectUri: this._loginRedirectUri,
                });
            }
        }
    };

    async redirectToLogin(
        stateArgs?: StateArgs,
        extraQueryParams?: Record<string, string>
    ) {
        this.updateAuthenticationState(AuthenticationState.InProgress);

        const url: URL = new URL(window.location.href);
        const params: URLSearchParams = url.searchParams;
        let updatedExtraQueryParams = extraQueryParams || {};

        if (params.get('origin') === 'userSignedOut') {
            params.delete('origin');
            updatedExtraQueryParams = {
                ...updatedExtraQueryParams,
                origin: 'userSignedOut',
            };
        }
        const asurionSessionId = UserBrowser.SessionId;
        const userBrowserId = UserBrowser.Id;

        if (asurionSessionId) {
            updatedExtraQueryParams.asurionSessionId = asurionSessionId.replace(
                /^"(.*)"$/,
                '$1'
            );
        }
        if (userBrowserId) {
            updatedExtraQueryParams.userBrowserId = userBrowserId.replace(
                /^"(.*)"$/,
                '$1'
            );
        }

        const state = JSON.stringify({
            redirectUri: url.toString(),
            ...stateArgs,
        });
        await performAuthorizationRequest({
            state,
            extras: updatedExtraQueryParams,
        });
    }

    async logout(args: LogoutArgs): Promise<void> {
        const accessToken = this._currentUser?.access_token;
        const idToken = this._currentUser?.id_token;
        this.updateUser(null);

        // Wait 1 second to let events handlers time to re-act on the logout
        await sleep(1000);

        if (accessToken) {
            await revokeToken(accessToken);
        }

        if (idToken) {
            await performSignoutRequest({
                redirectUri: args.redirectUri,
                idToken,
            });
        } else {
            window.location.href = args.redirectUri;
        }
    }

    async processMagicLink(magicLinkState: MagicLinkStateArgs): Promise<void> {
        try {
            verifyMagicLink(this._magicLinkRedirectUri);
            this.updateAuthenticationState(AuthenticationState.InProgress);
            const data = await requestToken({
                code: getQueryParam('code'),
                redirectUri: this._magicLinkRedirectUri,
            });
            const user = createUserFromToken(data);
            this.updateUser(user);
            const url = magicLinkState.redirectUri ?? '/';
            this._analyticsDispatcher.dispatch(EventName.CreatedMagicLink, {
                ExtraData: {
                    method: 'processMagicLink',
                    uri: url,
                    skipBuffer: true,
                },
            });

            window.location.href = url;
        } catch (error) {
            this.dispatchEvent(EventName.Error, {
                method: 'processMagicLink',
                error,
            });
            this.updateAuthenticationState(AuthenticationState.LoggedOut);
            monitor.error('Could not process magic link', error as Error);

            throw error;
        }
    }

    shouldSkipAuthorization(): boolean {
        const params = new URLSearchParams(window.location.search);
        return (
            this._authState === AuthenticationState.LoggedIn &&
            !(params.has('code') && params.has('state'))
        );
    }

    async processAuthorizationResponse(): Promise<void> {
        if (this.shouldSkipAuthorization()) {
            window.location.replace('/');
            return;
        }

        try {
            const authorizationResponse = processAuthorizationResponse();

            if (!authorizationResponse) {
                if (getAuthorizationRequestsCount() > 1) {
                    throw new AuthorizationError('Too many redirects');
                }

                const state = new URLSearchParams(window.location.search).get(
                    'state'
                );
                const redirectUri = state
                    ? (JSON.parse(state) as { redirectUri: string }).redirectUri
                    : '/';
                await this.redirectToLogin({ redirectUri });
            } else {
                this.updateAuthenticationState(AuthenticationState.InProgress);
                const { code, codeVerifier, state } = authorizationResponse;
                const data = await requestToken({
                    code,
                    redirectUri: this._loginRedirectUri,
                    codeVerifier,
                });
                const user = createUserFromToken(data);
                this.updateUser(user);
                resetAuthorizationRequestsCount();

                if (state) {
                    const { redirectUri } = JSON.parse(state) as {
                        redirectUri: string;
                    };

                    if (redirectUri) {
                        window.location.replace(redirectUri);
                    }
                }
            }
        } catch (error) {
            this.dispatchEvent(EventName.Error, {
                method: 'processAuthorizationResponse',
                error,
            });
            this.updateAuthenticationState(AuthenticationState.LoggedOut);

            const authError = 'Could not handle authorization response';
            monitor.error(authError, error as Error);
            throw new Error(authError);
        }
    }

    getUser(): User | null | undefined {
        return this._currentUser;
    }

    setUser = (user: UserInput | null): void => {
        try {
            const newUser = createUserFromToken(user);
            this.updateUser(newUser);
        } catch (error) {
            this.dispatchEvent(EventName.Error, { method: 'setUser', error });

            throw error;
        }
    };

    authenticationState(): AuthenticationState {
        return this._authState;
    }

    idToken(): string | undefined {
        return this._currentUser?.id_token;
    }

    accessToken(): string | undefined {
        return this._currentUser?.access_token;
    }

    subscriptions(): Subscription[] | undefined {
        return this._subscriptions;
    }

    addEventListener<T>(
        eventName: EventName,
        callback: (detail?: T) => void
    ): void {
        this._eventTarget.addEventListener(eventName, (evt) => {
            callback((evt as CustomEvent<T>).detail);
        });
    }

    private dispatchEvent<T>(eventName: EventName, detail?: T): boolean {
        return this._eventTarget.dispatchEvent(
            new CustomEvent(eventName, { detail })
        );
    }

    private updateUser(newUser?: User | null): void {
        if (newUser) {
            this.updateAuthenticationState(AuthenticationState.LoggedIn);
        } else {
            this.updateAuthenticationState(AuthenticationState.LoggedOut);
        }

        if (!this._currentUser && !newUser) return;

        const oldUser = this._currentUser;
        this._currentUser = newUser ?? null;
        this.onUser();

        if (!oldUser && newUser) {
            this.dispatchEvent(EventName.LoggedIn, { user: newUser });
        } else if (oldUser && !newUser) {
            this.dispatchEvent(EventName.LoggedOut, { prevUser: oldUser });
        } else if (
            newUser?.profile.asurion_id !== oldUser?.profile.asurion_id
        ) {
            this.dispatchEvent(EventName.UserChanged, {
                prevUser: oldUser,
                user: newUser,
            });
        } else if (newUser?.id_token !== oldUser?.id_token) {
            this.dispatchEvent(EventName.TokenRefreshed, { user: newUser });
        }

        this.persistState(this._currentUser);
    }

    private persistState(user: User | null) {
        if (!user) {
            sessionStorage.removeItem(USER_STORAGE_KEY);
        } else {
            sessionStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
        }
    }

    private onUser(): void {
        if (this._currentUser === undefined) {
            this._subscriptions = undefined;
        } else if (this._currentUser === null) {
            this._subscriptions = [];
        } else if (!this._currentUser.access_token) {
            this._subscriptions = [];
        } else {
            const accessToken = decodeJWT<AccessToken>(
                this._currentUser.access_token
            );
            this._subscriptions = extractSubscriptions(accessToken);
        }
    }

    private updateAuthenticationState(newAuthState: AuthenticationState) {
        if (this._authState !== newAuthState) {
            this._analyticsDispatcher.dispatch(
                authStateToAnalyticsEvent[newAuthState]
            );
            this.dispatchEvent(EventName.AuthStateChanged, {
                prevAuthState: this._authState,
                authState: newAuthState,
            });
            this._authState = newAuthState;
        }
    }

    private hydrate() {
        try {
            const user = sessionStorage.getItem(USER_STORAGE_KEY);
            if (user) {
                const storedUser = JSON.parse(user) as User;
                this.updateUser(storedUser);
            } else {
                this.updateUser(null);
            }
        } catch (error) {
            monitor.warning(
                'Could not hydrate authentication state from storage',
                error as Error
            );
            sessionStorage.removeItem(USER_STORAGE_KEY);
        }
    }
}

const personaSingletone = new Persona();

export default personaSingletone;
