const assertEnvVar = <T>(name: string, value?: T): T => {
    if (!value) {
        throw new Error(`Missing env var: ${name}`);
    }
    return value;
};

export const authority = new URL(
    assertEnvVar('AUTHORITY', process.env.AUTHORITY)
);
export const clientId = assertEnvVar('CLIENT_ID', process.env.CLIENT_ID);
export const genericErrorUrl = assertEnvVar(
    'GENERIC_ERROR_URL',
    process.env.GENERIC_ERROR_URL
);
