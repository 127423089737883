import { AuthorizationError, AuthorizationResponseError } from './errors';
import { deriveChallenge, generateRandom } from './crypto';

import { clientId } from '../../providers/env';
import { getOpenIdConfiguration } from './configurationService';
import { getQueryParams } from '../../utils/queryParams';
import { hasLoginRequestExpired } from './errors/knownErrors';
import { monitor } from '@soluto-private/mx-monitor';

const CURRENT_AUTHORIZATION_REQUEST = 'oidc_current_authorization_request';
const AUTHORIZATION_REQUEST_COUNT = 'oidc_authorization_request_count';
const CODE_VERIFIER_LENGTH = 128;

type LoginRequestState = {
    state: string;
    codeVerifier: string;
};

type AuthorizationRequestProps = {
    state: string;
    extras: Record<string, string>;
};

type AuthorizationResponse = {
    codeVerifier: string;
    code: string;
    state: string;
};

type LoginChallenge = {
    codeVerifier: string;
    codeChallenge: string;
    challengeMethod: string;
};

const createLoginChallenge = async (): Promise<LoginChallenge> => {
    const codeVerifier = generateRandom(CODE_VERIFIER_LENGTH);
    const challenge = await deriveChallenge(codeVerifier);

    return {
        codeVerifier,
        codeChallenge: challenge,
        challengeMethod: 'S256',
    };
};

export const getAuthorizationRequestsCount = () =>
    Number(localStorage.getItem(AUTHORIZATION_REQUEST_COUNT)) || 0;
export const resetAuthorizationRequestsCount = () =>
    localStorage.removeItem(AUTHORIZATION_REQUEST_COUNT);

const storeAuthRequestState = (loginRequestState: LoginRequestState) => {
    const requestCount = getAuthorizationRequestsCount();
    localStorage.setItem(
        CURRENT_AUTHORIZATION_REQUEST,
        JSON.stringify(loginRequestState)
    );
    localStorage.setItem(AUTHORIZATION_REQUEST_COUNT, String(requestCount + 1));
};

const popAuthRequestState = (): LoginRequestState | undefined => {
    const loginRequestState = localStorage.getItem(
        CURRENT_AUTHORIZATION_REQUEST
    );

    if (!loginRequestState) {
        return;
    }

    localStorage.removeItem(CURRENT_AUTHORIZATION_REQUEST);
    const { codeVerifier, state } = JSON.parse(
        loginRequestState
    ) as Partial<LoginRequestState>;

    if (!codeVerifier || !state) {
        throw new AuthorizationError(
            'Invalid login request state stored in storage'
        );
    }

    return { codeVerifier, state };
};

export const performAuthorizationRequest = async ({
    state,
    extras = {},
}: AuthorizationRequestProps): Promise<void> => {
    const { authorizationEndpoint } = await getOpenIdConfiguration();
    const { codeChallenge, codeVerifier, challengeMethod } =
        await createLoginChallenge();
    storeAuthRequestState({ state, codeVerifier });

    const requestMap = new URLSearchParams({
        redirect_uri: `${window.location.protocol}//${window.location.host}/auth/login`,
        client_id: clientId,
        response_type: 'code',
        state,
        scope: 'openid',
        code_challenge: codeChallenge,
        code_challenge_method: challengeMethod,
        audience: clientId,
        ...extras,
    });

    const url = `${authorizationEndpoint}?${requestMap.toString()}`;
    window.location.assign(url);

    if (process.env.NODE_ENV !== 'test') {
        await new Promise(() => {});
    }
};

const getAuthorizationResponseParams = () => {
    const queryParams = getQueryParams();
    const state = queryParams.state;
    const code = queryParams.code;
    const error = queryParams.error;
    const errorUri = queryParams.error_uri;
    const errorDescription = queryParams.error_description;

    return { state, code, error, errorDescription, errorUri };
};

export const processAuthorizationResponse = ():
    | AuthorizationResponse
    | undefined => {
    const authorizationResponse = getAuthorizationResponseParams();

    if (authorizationResponse.error) {
        if (hasLoginRequestExpired(authorizationResponse)) {
            return undefined;
        }

        throw new AuthorizationResponseError(
            authorizationResponse.error,
            authorizationResponse.errorUri,
            authorizationResponse.errorDescription
        );
    }

    const authRequestState = popAuthRequestState();

    if (!authRequestState) {
        const msg = 'No active login request found in storage';
        monitor.warning(msg, new AuthorizationError(msg));
        return undefined;
    } else if (authRequestState.state !== authorizationResponse.state) {
        const msg = 'Mismatch between the request state to the response state';
        monitor.warning(msg, new AuthorizationError(msg));
        return undefined;
    }

    return {
        state: authorizationResponse.state,
        code: authorizationResponse.code,
        codeVerifier: authRequestState.codeVerifier,
    };
};
