import AuthorizationError from './AuthorizationError';

class AuthorizationResponseError extends AuthorizationError {
    uri: string;
    description: string;

    constructor(message: string, uri: string, description: string) {
        super(message);
        this.uri = uri;
        this.description = description;
    }
}

export default AuthorizationResponseError;
