import {
    AcrValue,
    AcrValueToken,
    AuthenticationState,
    EventName,
    LogoutArgs,
    StateArgs,
    Subscription,
    User,
    UserInput,
} from './types';
import {
    createMappedExtraQueryParams,
    formatAcrValue,
    getQueryParam,
} from './utils';

import { monitor } from '@soluto-private/mx-monitor';
import personaInstance from './persona';

/**
 * Returns the current access token from the authenticated user
 */
export const getAccessToken = (): string | undefined =>
    personaInstance.accessToken();

/**
 * Returns the current id token from the authenticated user
 */
export const getIdToken = (): string | undefined => personaInstance.idToken();

/**
 * Returns a list of active partners of the authenticated user
 */
export const getUserSubscriptions = (): Subscription[] | undefined =>
    personaInstance.subscriptions();

/**
 * Returns the current authentication state
 */
export const getAuthenticationState = (): AuthenticationState =>
    personaInstance.authenticationState();

/**
 * Logs the user out and redirect to the given path
 * @param args - Logout state args
 */
export const logout = async (logoutArgs: LogoutArgs) =>
    await personaInstance.logout(logoutArgs);

/**
 * Raises a request to refresh the user's token's
 * @deprecated
 */
export const refreshToken = () => {
    const error = new Error('Deprecated API call');
    monitor.warning('refreshToken is deprecated', error);
    return null;
};

/**
 * Raises a request to redirect to user to the login page.
 * @param state
 * @param acr_values
 */
export const redirectToLogin = async (
    state?: StateArgs,
    acr_values?: AcrValue[]
) => {
    const paramMappings: Record<string, string> = {
        partner: 'mxclient',
        campaign_id: 'campaign',
    };

    const extraQueryParams = createMappedExtraQueryParams(
        Object.entries(paramMappings).reduce<Record<string, string>>(
            (params, [k, v]) => {
                const param = getQueryParam(v);
                if (param) {
                    params[k] = param;
                }
                return params;
            },
            { ui_locales: navigator.language }
        )
    );

    // If acr_values are not explicitly provided, default to email sign-in
    // or email-phone if we have a defined partner
    if (!acr_values?.length) {
        acr_values = extraQueryParams.partner
            ? ['sign-in:email-phone']
            : ['sign-in:email'];
    }

    // Asurion ID supports acr_values in querystring as a space-delimited multivalue parameter
    if (acr_values && acr_values.length > 0) {
        extraQueryParams.acr_values = acr_values.join(' ');
    }

    await personaInstance.redirectToLogin(state, extraQueryParams);
};

export type StepUpByClientChannelArgs = {
    state?: StateArgs;
    clientChannelId: string;
    subscriptionId?: string;
};

/**
 * Perform step-up authentication for a particular client channel. Optionally, provide a subscription id
 * to perform step-up authentication for a specific agreement.
 * @param args StepUpByClientChannelArgs
 */
export const stepUpByClientChannel = async ({
    state,
    clientChannelId,
    subscriptionId,
}: StepUpByClientChannelArgs) =>
    await redirectToLogin(state, [
        formatAcrValue(AcrValueToken.StepUp, clientChannelId, subscriptionId),
    ]);

export type StepUpByPartnerArgs = {
    state?: StateArgs;
    partner: string;
};

/**
 * Perform step-up authentication for a particular partner.
 * @param args StepUpByPartnerArgs
 */
export const stepUpByPartner = async ({
    state,
    partner,
}: StepUpByPartnerArgs) => {
    const params = new URLSearchParams(window.location.search);
    params.set('mxclient', partner);
    window.history.pushState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`
    );
    await redirectToLogin(state, [AcrValueToken.StepUp]);
};

/**
 * Tries to get the currently stored user
 * @returns Currently authenticated user
 */
export const getUser = async (): Promise<User | null | undefined> =>
    await Promise.resolve(personaInstance.getUser());
/**
 * Manually sets the authenticated user
 * @param user A valid non-expired user object.
 */
export const setUser = (user: UserInput | null): void =>
    personaInstance.setUser(user);
export function addEventListener(
    eventName: EventName.LoggedIn | EventName.TokenRefreshed,
    callback: (data: { user: User }) => void
): void;
export function addEventListener(
    eventName: EventName.LoggedOut,
    callback: (data: { prevUser: User }) => void
): void;
export function addEventListener(
    eventName: EventName.UserChanged,
    callback: (data: { prevUser: User; user: User }) => void
): void;
export function addEventListener(
    eventName: EventName.Error,
    callback: (data: { method: string; error: Error }) => void
): void;
export function addEventListener(
    eventName: EventName.AuthStateChanged,
    callback: (data: {
        prevAuthState: AuthenticationState;
        authState: AuthenticationState;
    }) => void
): void;
export function addEventListener<T>(
    eventName: EventName,
    callback: (detail?: T) => void
): void {
    personaInstance.addEventListener(eventName, callback);
}
