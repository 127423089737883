import type { AccessToken } from '../types';
import type { Subscription } from '../../types';

const horizonSourcePlatform = 'HORIZON';

const extractSubscriptions = (
    accessToken?: Partial<AccessToken> | null
): Subscription[] => {
    const personas = accessToken?.ext?.personas;

    if (!personas) {
        return [];
    }

    const subscriptions = personas
        .filter((persona) => persona.sourcePlatform === horizonSourcePlatform)
        .map((persona) => {
            const [clientChannelId, subscriptionNumber] =
                persona.externalId.split('|');
            return { clientChannelId, subscriptionNumber };
        });

    return subscriptions;
};

export default extractSubscriptions;
