import {
    createMappedExtraQueryParams,
    getQueryParam,
} from './utils/queryParams';

import { decode as decodeBase64 } from 'js-base64';
import { monitor } from '@soluto-private/mx-monitor';
import personaInstance from './persona';
import { verifyMagicLink } from './utils/verifyMagicLink';

const MAGIC_LINK_PATH = 'magic-link';
const LOGIN_PATH = 'login';

const router = async (): Promise<void> => {
    const { pathname } = window.location;
    if (pathname.includes(LOGIN_PATH)) {
        await personaInstance.processAuthorizationResponse();
    } else if (pathname.includes(MAGIC_LINK_PATH)) {
        try {
            const stateString = getQueryParam('state');
            const state = stateString
                ? (JSON.parse(decodeBase64(stateString)) as {
                      redirectUri?: string;
                  })
                : {};
            const redirectUri = new URL(
                state.redirectUri ?? '/',
                window.location.href
            ).toString();
            verifyMagicLink(redirectUri);

            const extraQueryParams: Record<string, string> =
                createMappedExtraQueryParams();

            await personaInstance.redirectToLogin(
                { ...state, redirectUri },
                extraQueryParams
            );
        } catch (error) {
            monitor.warning('Invalid magic link', error as Error);
            await personaInstance.redirectToLogin({ redirectUri: '/' });
        }
    } else {
        const error = new Error('Unsupported route');
        monitor.warning('Unexpected authentication route', error, {
            pathname,
        });
        throw error;
    }
};

export default router;
