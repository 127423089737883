const MAGIC_LINK_WHITELIST = [
    'my.asurion.com',
    'stable.my.asurion53.com',
    'my.nonprod-asurion53.com',
];

export const verifyMagicLink = (link: string) => {
    try {
        const { hostname } = new URL(link);
        if (!hostname || !MAGIC_LINK_WHITELIST.includes(hostname)) {
            throw new Error('Not a valid magic link!');
        }
    } catch (err) {
        throw new Error('Not a valid magic link!');
    }
};
