import { getOpenIdConfiguration } from './configurationService';

type SignoutRequestParams = {
    redirectUri: string;
    idToken: string;
};

export const performSignoutRequest = async ({
    redirectUri,
    idToken,
}: SignoutRequestParams): Promise<void> => {
    const { endSessionEndpoint } = await getOpenIdConfiguration();

    const requestMap = new URLSearchParams({
        id_token_hint: idToken,
        post_logout_redirect_uri: redirectUri,
    });

    const url = `${endSessionEndpoint}?${requestMap.toString()}`;
    window.location.assign(url);
};
