export const getQueryParams = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
    });

    return params as unknown as Record<string, string>;
};

export const getQueryParam = <T = string>(key: string): T => {
    const params = getQueryParams();
    return params[key] as T;
};

/**
 * Helper to create extraQuery params w/ cid=test mapped to origin=test.
 * This mapping is required to filter out test data from AsurionID OIDC client reporting
 */
export const createMappedExtraQueryParams = (
    initialParams?: Record<string, string>
): Record<string, string> => {
    const cid: string = getQueryParam('cid');
    let origin: string = getQueryParam('origin');

    //If cid=test was passed, remove and remap to origin param
    if (cid && cid === 'test') {
        origin = 'test';
    }

    return { ...initialParams, origin };
};
