import * as env from '../../providers/env';

import { monitor } from '@soluto-private/mx-monitor';

const CONFIGURATION_STORAGE_KEY = 'ONESERVICE_AUTH_CONFIGURATION';

type AuthorizationServiceConfiguration = {
    authorizationEndpoint: string;
    tokenEndpoint: string;
    revocationEndpoint: string;
    endSessionEndpoint: string;
};

let _configuration: AuthorizationServiceConfiguration | undefined;

const defaultConfiguration: AuthorizationServiceConfiguration = {
    authorizationEndpoint: new URL('oauth2/auth', env.authority).href,
    tokenEndpoint: new URL('oauth2/token', env.authority).href,
    revocationEndpoint: new URL('oauth2/revoke', env.authority).href,
    endSessionEndpoint: new URL('oauth2/sessions/logout', env.authority).href,
};

const fetchFromIssuer =
    async (): Promise<AuthorizationServiceConfiguration> => {
        const fullUrl = new URL(
            '/.well-known/openid-configuration',
            env.authority
        );
        const data = await fetch(fullUrl.href);

        if (!data.ok) {
            const error = new Error('Failed to fetch');
            monitor.warning(
                'Could not fetch open id configuration, fallback to default',
                error,
                {
                    statusCode: data.status,
                    statusText: data.statusText,
                }
            );

            return defaultConfiguration;
        }

        const configuration = (await data.json()) as Record<string, string>;

        return {
            authorizationEndpoint: configuration.authorization_endpoint,
            tokenEndpoint: configuration.token_endpoint,
            revocationEndpoint: configuration.revocation_endpoint,
            endSessionEndpoint: configuration.end_session_endpoint,
        };
    };

const getConfigurationFromCache = ():
    | AuthorizationServiceConfiguration
    | undefined => {
    const configurationCache = sessionStorage.getItem(
        CONFIGURATION_STORAGE_KEY
    );

    if (configurationCache) {
        try {
            const configuration = JSON.parse(
                configurationCache
            ) as AuthorizationServiceConfiguration;

            if (
                !configuration.authorizationEndpoint ||
                !configuration.tokenEndpoint ||
                !configuration.endSessionEndpoint ||
                !configuration.revocationEndpoint
            ) {
                throw new Error('Cached auth configuration is invalid');
            }

            return configuration;
        } catch (error) {
            monitor.warning(
                'Could not get configuration from cache',
                error as Error,
                { configurationCache }
            );
            sessionStorage.removeItem(CONFIGURATION_STORAGE_KEY);
        }
    }
};

const persistConfiguration = (
    configuration: AuthorizationServiceConfiguration
) => {
    sessionStorage.setItem(
        CONFIGURATION_STORAGE_KEY,
        JSON.stringify(configuration)
    );
};

export const getOpenIdConfiguration =
    async (): Promise<AuthorizationServiceConfiguration> => {
        let configuration = _configuration ?? getConfigurationFromCache();

        if (!configuration) {
            configuration = await fetchFromIssuer();
            persistConfiguration(configuration);
        }

        _configuration = configuration;

        return _configuration;
    };
