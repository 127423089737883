class EventEmitter implements EventTarget {
    delegate: DocumentFragment;

    constructor() {
        this.delegate = document.createDocumentFragment();
    }

    addEventListener(
        type: string,
        listener: EventListenerOrEventListenerObject | null,
        options?: boolean | AddEventListenerOptions
    ): void {
        this.delegate.addEventListener(type, listener, options);
    }

    dispatchEvent(event: Event): boolean {
        return this.delegate.dispatchEvent(event);
    }

    removeEventListener(
        type: string,
        callback: EventListenerOrEventListenerObject | null,
        options?: EventListenerOptions | boolean
    ): void {
        this.delegate.removeEventListener(type, callback, options);
    }
}

export default EventEmitter;
