import { getAuthorizationRequestsCount } from '../authorizationRequest';

class AuthorizationError extends Error {
    authorizationRequestCount: number;

    constructor(message: string) {
        super(message);
        this.authorizationRequestCount = getAuthorizationRequestsCount();
    }
}

export default AuthorizationError;
