import type { User, UserInput } from '../../types';

import type { AccessToken } from '../types';
import { assertTokenExpiration } from './assertTokenExpiration';
import decodeJWT from 'jwt-decode';

export const createUserFromToken = (
    tokenResponse: UserInput | null
): User | null => {
    if (!tokenResponse) return null;

    if (!tokenResponse.id_token)
        throw new Error('Invalid user: id_token is missing!');
    if (!tokenResponse.access_token)
        throw new Error('Invalid user: access_token is missing!');

    const parsedIdToken = decodeJWT<{ exp?: number }>(tokenResponse.id_token);
    const parsedAccessToken = decodeJWT<Partial<AccessToken>>(
        tokenResponse.access_token
    );

    if (
        !assertTokenExpiration(parsedAccessToken) ||
        !assertTokenExpiration(parsedIdToken)
    ) {
        throw new Error('Invalid user: invalid token expiration provided.');
    }

    const user: User = {
        id_token: tokenResponse.id_token,
        access_token: tokenResponse.access_token,
        scope: parsedAccessToken.scp?.join(' ') ?? '',
        expires_at: parsedAccessToken.exp,
        state: '',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: parsedAccessToken.ext!,
    };

    return user;
};
