const HAS_CRYPTO = () => typeof window !== 'undefined' && !!window.crypto;
const HAS_SUBTLE_CRYPTO = () => HAS_CRYPTO() && !!window.crypto.subtle;
const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const CODE_VERIFIER_MIN_LENGTH = 43;
const CODE_VERIFIER_MAX_LENGTH = 128;

const bufferToString = (buffer: Uint8Array): string =>
    buffer.reduce((str, char) => `${str}${CHARSET[char % CHARSET.length]}`, '');

const urlSafe = (buffer: Uint8Array): string => {
    const encoded = window.btoa(
        // @ts-expect-error not the best solution, but apply can accept a Uint8Array
        String.fromCharCode.apply(null, new Uint8Array(buffer))
    );

    return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const generateRandom = (size: number): string => {
    const buffer = new Uint8Array(size);

    if (!HAS_CRYPTO()) {
        throw new Error('window.crypto is unavailable.');
    } else {
        window.crypto.getRandomValues(buffer);
    }

    return bufferToString(buffer);
};

export const deriveChallenge = async (code: string): Promise<string> => {
    if (
        code.length < CODE_VERIFIER_MIN_LENGTH ||
        code.length > CODE_VERIFIER_MAX_LENGTH
    ) {
        throw new Error('Invalid code length.');
    }

    if (!HAS_SUBTLE_CRYPTO()) {
        throw new Error('window.crypto.subtle is unavailable.');
    }

    const textEncoder = new TextEncoder();
    const encodedCode = textEncoder.encode(code);
    const buffer = await crypto.subtle.digest('SHA-256', encodedCode);

    return urlSafe(new Uint8Array(buffer));
};
