export const hasLoginRequestExpired = (authorizationResponse: {
    errorDescription: string;
    error: string;
}) => {
    return (
        authorizationResponse.error === 'request_unauthorized' &&
        authorizationResponse.errorDescription ===
            'The request could not be authorized. The login request has expired. Please try again.'
    );
};
