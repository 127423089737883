import { genericErrorUrl } from './providers/env';
import handleRoute from './routes';
import { markPerformance } from './utils/markPerformance';
import { monitor } from '@soluto-private/mx-monitor';

const displayGenericErrorPage = () => (window.location.href = genericErrorUrl);

export async function bootstrap(_props: unknown): Promise<void> {
    markPerformance('[mx-auth] - bootstrap');
}

export async function mount(_props: unknown): Promise<void> {
    markPerformance('[mx-auth] - mount');

    try {
        await handleRoute();
    } catch (err) {
        monitor.warning('Error handling authentication route', err as Error);
        displayGenericErrorPage();
    }
}

export async function unmount(_props: unknown): Promise<void> {
    markPerformance('[mx-auth] - unmount');
}

export * from './types';
export * from './api';
