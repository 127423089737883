import { clientId } from '../../providers/env';
import { getOpenIdConfiguration } from './configurationService';
import { monitor } from '@soluto-private/mx-monitor';

type RequestTokenProps = {
    redirectUri: string;
    code: string;
    codeVerifier?: string;
};

type TokenResponse = {
    id_token: string;
    access_token: string;
    token_type: 'bearer';
    scope: string;
    expires_in: number;
};

type RequestToken = (props: RequestTokenProps) => Promise<TokenResponse>;

export const requestToken: RequestToken = async ({
    code,
    redirectUri,
    codeVerifier,
}) => {
    const { tokenEndpoint } = await getOpenIdConfiguration();
    const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
            code,
            client_id: clientId,
            redirect_uri: redirectUri,
            grant_type: 'authorization_code',
            ...(codeVerifier ? { code_verifier: codeVerifier } : {}),
        }),
    });

    const data = (await response.json()) as TokenResponse;

    return data;
};

export const revokeToken = async (accessToken: string): Promise<void> => {
    const { revocationEndpoint } = await getOpenIdConfiguration();

    try {
        const response = await fetch(revocationEndpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                client_id: clientId,
                token_type_hint: 'access_token',
                token: accessToken,
            }),
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    } catch (error) {
        monitor.warning('Could not revoke token', error as Error);
    }
};
