import type { UserProfile } from './persona/types';

export type User = {
    access_token: string;
    id_token: string;
    scope: string;
    profile: UserProfile;
    expires_at: number;
    state: unknown;
};

export enum EventName {
    LoggedIn = 'LOGGED_IN',
    UserChanged = 'USER_CHANGED',
    TokenRefreshed = 'TOKEN_REFRESHED',
    LoggedOut = 'LOGGED_OUT',
    AuthStateChanged = 'AUTH_STATE_CHANGED',
    Error = 'ERROR',
    CreatedMagicLink = 'CREATED_MAGIC_LINK',
}

export enum AuthenticationState {
    LoggedOut = 'LOGGED_OUT',
    InProgress = 'IN_PROGRESS',
    LoggedIn = 'LOGGED_IN',
}

export type StateArgs = {
    redirectUri?: string;
    [key: string]: unknown;
};

export type MagicLinkStateArgs = {
    nonce: string;
} & StateArgs;

export type LogoutArgs = {
    redirectUri: string;
};

export type UserInput = {
    id_token: string;
    access_token: string;
    refresh_token?: string | null;
};

export type Subscription = {
    clientChannelId: string;
    subscriptionNumber: string;
};

// TODO: Make a stricter type for client channel
export type ClientChannel = string;

export enum SignIn {
    Email = 'email',
    EmailOrPhone = 'email-phone',
    Phone = 'phone',
}

export type AcrValue =
    | `${AcrValueToken.SignIn}:${SignIn}`
    | `${AcrValueToken.StepUp}`
    | `${AcrValueToken.StepUp}:${ClientChannel}`
    | `${AcrValueToken.StepUp}:${ClientChannel}:${string}`;

export enum AcrValueToken {
    SignIn = 'sign-in',
    StepUp = 'step-up',
}
